<template>
    <div class="">
        <div class="message-container-block">
            <div class="message-contact"  :class="{'visible':smallScreen}">
                <div class="search-contact">
                    <input type="search" placeholder="Rechercher un contact">
                </div>
                <div class="contact-contain">
                    <div class="contact-item" v-for="(con,cle) in 5" :key="cle">
                        <div class="avatar-contact">
                            <img src="@/assets/img/promoteur-maisonier.webp" alt="">
                        </div>
                        <div class="contact-info">
                            <div class="info-user">
                                <span class="contact-name">
                                    Anderson pedrolotti
                                </span>
                                <span class="contact-message">
                                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                                </span>
                            </div>
                            <div class="unread-message">
                                3
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chat-container"  :class="{'visible':!smallScreen}">
                <div class="chat-container-header">
                    <div class="active-contact">
                        <img src="@/assets/img/promoteur-maisonier.webp" alt="">
                        <span>Anderson pedrolotti</span>
                    </div>
                    <div class="controls">
                        <div>
                            <i class="ik ik-check"></i>
                            <span>Marquer comme lu</span>
                        </div>
                        <div>
                            <i class="ik ik-trash-2"></i>
                            <span>Archiver la discussion</span>
                        </div>
                    </div>
                </div>
                <div class="chat-area">
                    <div class="message-item" v-for="(msg,gg) in gomba" :key="gg" :class="{'right':msg}">
                        <!-- <img src="@/assets/img/promoteur-maisonier.webp" alt=""> -->
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, natus! Ullam, nulla?
                        </p>
                    </div>
                </div>
                <div class="chat-container-bottom">
                    <input type="text" placeholder="Votre message">
                    <button>
                        <i class="ik ik-navigation"></i>
                        <span>Envoyer</span>
                    </button>
                </div>
            </div>
        </div>
       <!-- <main class="content">
            <div>
                <div class="card">
                    <div class="row g-0">
                        <div class="col-12 col-lg-5 col-xl-3 border-right">

                            <div class="px-4 d-none d-md-block">
                                <div class="d-flex align-items-center">
                                    <div class="flex-grow-1">
                                        <input type="text" class="form-control my-3" placeholder="Search...">
                                    </div>
                                </div>
                            </div>

                            <a href="#" class="list-group-item list-group-item-action border-0">
                                <div class="badge bg-success float-right">5</div>
                                <div class="d-flex align-items-start">
                                    <img src="https://bootdey.com/img/Content/avatar/avatar5.png" class="rounded-circle mr-1" alt="Vanessa Tucker" width="40" height="40">
                                    <div class="flex-grow-1 ml-3">
                                        Vanessa Tucker
                                        <div class="small"><span class="fas fa-circle chat-online"></span> Online</div>
                                    </div>
                                </div>
                            </a>
                            <a href="#" class="list-group-item list-group-item-action border-0">
                                <div class="badge bg-success float-right">2</div>
                                <div class="d-flex align-items-start">
                                    <img src="https://bootdey.com/img/Content/avatar/avatar2.png" class="rounded-circle mr-1" alt="William Harris" width="40" height="40">
                                    <div class="flex-grow-1 ml-3">
                                        William Harris
                                        <div class="small"><span class="fas fa-circle chat-online"></span> Online</div>
                                    </div>
                                </div>
                            </a>
                            <a href="#" class="list-group-item list-group-item-action border-0">
                                <div class="d-flex align-items-start">
                                    <img src="https://bootdey.com/img/Content/avatar/avatar3.png" class="rounded-circle mr-1" alt="Sharon Lessman" width="40" height="40">
                                    <div class="flex-grow-1 ml-3">
                                        Sharon Lessman
                                        <div class="small"><span class="fas fa-circle chat-online"></span> Online</div>
                                    </div>
                                </div>
                            </a>
                            <a href="#" class="list-group-item list-group-item-action border-0">
                                <div class="d-flex align-items-start">
                                    <img src="https://bootdey.com/img/Content/avatar/avatar4.png" class="rounded-circle mr-1" alt="Christina Mason" width="40" height="40">
                                    <div class="flex-grow-1 ml-3">
                                        Christina Mason
                                        <div class="small"><span class="fas fa-circle chat-offline"></span> Offline</div>
                                    </div>
                                </div>
                            </a>
                            <a href="#" class="list-group-item list-group-item-action border-0">
                                <div class="d-flex align-items-start">
                                    <img src="https://bootdey.com/img/Content/avatar/avatar5.png" class="rounded-circle mr-1" alt="Fiona Green" width="40" height="40">
                                    <div class="flex-grow-1 ml-3">
                                        Fiona Green
                                        <div class="small"><span class="fas fa-circle chat-offline"></span> Offline</div>
                                    </div>
                                </div>
                            </a>
                            <a href="#" class="list-group-item list-group-item-action border-0">
                                <div class="d-flex align-items-start">
                                    <img src="https://bootdey.com/img/Content/avatar/avatar2.png" class="rounded-circle mr-1" alt="Doris Wilder" width="40" height="40">
                                    <div class="flex-grow-1 ml-3">
                                        Doris Wilder
                                        <div class="small"><span class="fas fa-circle chat-offline"></span> Offline</div>
                                    </div>
                                </div>
                            </a>
                            <a href="#" class="list-group-item list-group-item-action border-0">
                                <div class="d-flex align-items-start">
                                    <img src="https://bootdey.com/img/Content/avatar/avatar4.png" class="rounded-circle mr-1" alt="Haley Kennedy" width="40" height="40">
                                    <div class="flex-grow-1 ml-3">
                                        Haley Kennedy
                                        <div class="small"><span class="fas fa-circle chat-offline"></span> Offline</div>
                                    </div>
                                </div>
                            </a>
                            <a href="#" class="list-group-item list-group-item-action border-0">
                                <div class="d-flex align-items-start">
                                    <img src="https://bootdey.com/img/Content/avatar/avatar3.png" class="rounded-circle mr-1" alt="Jennifer Chang" width="40" height="40">
                                    <div class="flex-grow-1 ml-3">
                                        Jennifer Chang
                                        <div class="small"><span class="fas fa-circle chat-offline"></span> Offline</div>
                                    </div>
                                </div>
                            </a>

                            <hr class="d-block d-lg-none mt-1 mb-0">
                        </div>
                        <div class="col-12 col-lg-7 col-xl-9">
                            <div class="py-2 px-4 border-bottom d-none d-lg-block">
                                <div class="d-flex align-items-center py-1">
                                    <div class="position-relative">
                                        <img src="https://bootdey.com/img/Content/avatar/avatar3.png" class="rounded-circle mr-1" alt="Sharon Lessman" width="40" height="40">
                                    </div>
                                    <div class="flex-grow-1 pl-3">
                                        <strong>Sharon Lessman</strong>
                                        <div class="text-muted small"><em>Typing...</em></div>
                                    </div>
                                </div>
                            </div>

                            <div class="position-relative">
                                <div class="chat-messages p-4">

                                    <div class="chat-message-right pb-4">
                                        <div>
                                            <img src="https://bootdey.com/img/Content/avatar/avatar1.png" class="rounded-circle mr-1" alt="Chris Wood" width="40" height="40">
                                            <div class="text-muted small text-nowrap mt-2">2:33 am</div>
                                        </div>
                                        <div class="flex-shrink-1 bg-light rounded py-2 px-3 mr-3">
                                            <div class="font-weight-bold mb-1">You</div>
                                            Lorem ipsum dolor sit amet, vis erat denique in, dicunt prodesset te vix.
                                        </div>
                                    </div>

                                    <div class="chat-message-left pb-4">
                                        <div>
                                            <img src="https://bootdey.com/img/Content/avatar/avatar3.png" class="rounded-circle mr-1" alt="Sharon Lessman" width="40" height="40">
                                            <div class="text-muted small text-nowrap mt-2">2:34 am</div>
                                        </div>
                                        <div class="flex-shrink-1 bg-light rounded py-2 px-3 ml-3">
                                            <div class="font-weight-bold mb-1">Sharon Lessman</div>
                                            Sit meis deleniti eu, pri vidit meliore docendi ut, an eum erat animal commodo.
                                        </div>
                                    </div>

                                    <div class="chat-message-right mb-4">
                                        <div>
                                            <img src="https://bootdey.com/img/Content/avatar/avatar1.png" class="rounded-circle mr-1" alt="Chris Wood" width="40" height="40">
                                            <div class="text-muted small text-nowrap mt-2">2:35 am</div>
                                        </div>
                                        <div class="flex-shrink-1 bg-light rounded py-2 px-3 mr-3">
                                            <div class="font-weight-bold mb-1">You</div>
                                            Cum ea graeci tractatos.
                                        </div>
                                    </div>

                                    <div class="chat-message-left pb-4">
                                        <div>
                                            <img src="https://bootdey.com/img/Content/avatar/avatar3.png" class="rounded-circle mr-1" alt="Sharon Lessman" width="40" height="40">
                                            <div class="text-muted small text-nowrap mt-2">2:36 am</div>
                                        </div>
                                        <div class="flex-shrink-1 bg-light rounded py-2 px-3 ml-3">
                                            <div class="font-weight-bold mb-1">Sharon Lessman</div>
                                            Sed pulvinar, massa vitae interdum pulvinar, risus lectus porttitor magna, vitae commodo lectus mauris et velit.
                                            Proin ultricies placerat imperdiet. Morbi varius quam ac venenatis tempus.
                                        </div>
                                    </div>

                                    <div class="chat-message-left pb-4">
                                        <div>
                                            <img src="https://bootdey.com/img/Content/avatar/avatar3.png" class="rounded-circle mr-1" alt="Sharon Lessman" width="40" height="40">
                                            <div class="text-muted small text-nowrap mt-2">2:37 am</div>
                                        </div>
                                        <div class="flex-shrink-1 bg-light rounded py-2 px-3 ml-3">
                                            <div class="font-weight-bold mb-1">Sharon Lessman</div>
                                            Cras pulvinar, sapien id vehicula aliquet, diam velit elementum orci.
                                        </div>
                                    </div>

                                    <div class="chat-message-right mb-4">
                                        <div>
                                            <img src="https://bootdey.com/img/Content/avatar/avatar1.png" class="rounded-circle mr-1" alt="Chris Wood" width="40" height="40">
                                            <div class="text-muted small text-nowrap mt-2">2:38 am</div>
                                        </div>
                                        <div class="flex-shrink-1 bg-light rounded py-2 px-3 mr-3">
                                            <div class="font-weight-bold mb-1">You</div>
                                            Lorem ipsum dolor sit amet, vis erat denique in, dicunt prodesset te vix.
                                        </div>
                                    </div>

                                    <div class="chat-message-left pb-4">
                                        <div>
                                            <img src="https://bootdey.com/img/Content/avatar/avatar3.png" class="rounded-circle mr-1" alt="Sharon Lessman" width="40" height="40">
                                            <div class="text-muted small text-nowrap mt-2">2:39 am</div>
                                        </div>
                                        <div class="flex-shrink-1 bg-light rounded py-2 px-3 ml-3">
                                            <div class="font-weight-bold mb-1">Sharon Lessman</div>
                                            Sit meis deleniti eu, pri vidit meliore docendi ut, an eum erat animal commodo.
                                        </div>
                                    </div>

                                    <div class="chat-message-right mb-4">
                                        <div>
                                            <img src="https://bootdey.com/img/Content/avatar/avatar1.png" class="rounded-circle mr-1" alt="Chris Wood" width="40" height="40">
                                            <div class="text-muted small text-nowrap mt-2">2:40 am</div>
                                        </div>
                                        <div class="flex-shrink-1 bg-light rounded py-2 px-3 mr-3">
                                            <div class="font-weight-bold mb-1">You</div>
                                            Cum ea graeci tractatos.
                                        </div>
                                    </div>

                                    <div class="chat-message-right mb-4">
                                        <div>
                                            <img src="https://bootdey.com/img/Content/avatar/avatar1.png" class="rounded-circle mr-1" alt="Chris Wood" width="40" height="40">
                                            <div class="text-muted small text-nowrap mt-2">2:41 am</div>
                                        </div>
                                        <div class="flex-shrink-1 bg-light rounded py-2 px-3 mr-3">
                                            <div class="font-weight-bold mb-1">You</div>
                                            Morbi finibus, lorem id placerat ullamcorper, nunc enim ultrices massa, id dignissim metus urna eget purus.
                                        </div>
                                    </div>

                                    <div class="chat-message-left pb-4">
                                        <div>
                                            <img src="https://bootdey.com/img/Content/avatar/avatar3.png" class="rounded-circle mr-1" alt="Sharon Lessman" width="40" height="40">
                                            <div class="text-muted small text-nowrap mt-2">2:42 am</div>
                                        </div>
                                        <div class="flex-shrink-1 bg-light rounded py-2 px-3 ml-3">
                                            <div class="font-weight-bold mb-1">Sharon Lessman</div>
                                            Sed pulvinar, massa vitae interdum pulvinar, risus lectus porttitor magna, vitae commodo lectus mauris et velit.
                                            Proin ultricies placerat imperdiet. Morbi varius quam ac venenatis tempus.
                                        </div>
                                    </div>

                                    <div class="chat-message-right mb-4">
                                        <div>
                                            <img src="https://bootdey.com/img/Content/avatar/avatar1.png" class="rounded-circle mr-1" alt="Chris Wood" width="40" height="40">
                                            <div class="text-muted small text-nowrap mt-2">2:43 am</div>
                                        </div>
                                        <div class="flex-shrink-1 bg-light rounded py-2 px-3 mr-3">
                                            <div class="font-weight-bold mb-1">You</div>
                                            Lorem ipsum dolor sit amet, vis erat denique in, dicunt prodesset te vix.
                                        </div>
                                    </div>

                                    <div class="chat-message-left pb-4">
                                        <div>
                                            <img src="https://bootdey.com/img/Content/avatar/avatar3.png" class="rounded-circle mr-1" alt="Sharon Lessman" width="40" height="40">
                                            <div class="text-muted small text-nowrap mt-2">2:44 am</div>
                                        </div>
                                        <div class="flex-shrink-1 bg-light rounded py-2 px-3 ml-3">
                                            <div class="font-weight-bold mb-1">Sharon Lessman</div>
                                            Sit meis deleniti eu, pri vidit meliore docendi ut, an eum erat animal commodo.
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="flex-grow-0 py-3 px-4 border-top">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Type your message">
                                    <button class="btn btn-primary">Send</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </main> -->
    </div>
</template>
<script>
  /*import AnnonceForm from "./AnnonceForm.vue";
  import AnnonceComponent from './Annonce.vue';
  import DetailsAnnonce from './DetailsAnnonce.vue'
  import SearchForm from "@/components/parts/SearchForm.vue";*/
  import { mapGetters} from "vuex"
  const php  = require ( 'phpjs' ) ; 

export default {
  name: "reservations",
  components: {

  },
   watch: {
   
    },
  data: () => ({
      gomba:[
          true,false,false,true,true,false
      ],
      smallScreen:false
  }),
}
</script>
<style scoped>
     body{margin-top:20px;}

    .chat-online {
        color: #34ce57
    }

    .chat-offline {
        color: #e4606d
    }

    .chat-messages {
        display: flex;
        flex-direction: column;
        max-height: 800px;
        overflow-y: scroll
    }

    .chat-message-left,
    .chat-message-right {
        display: flex;
        flex-shrink: 0
    }

    .chat-message-left {
        margin-right: auto
    }

    .chat-message-right {
        flex-direction: row-reverse;
        margin-left: auto
    }
    .py-3 {
        padding-top: 1rem!important;
        padding-bottom: 1rem!important;
    }
    .px-4 {
        padding-right: 1.5rem!important;
        padding-left: 1.5rem!important;
    }
    .flex-grow-0 {
        flex-grow: 0!important;
    }
    .border-top {
        border-top: 1px solid #dee2e6!important;
    }
    .message-container-block{
        margin-bottom: 30px;
        display: flex;
        height: 74vh;
        width: 100%;
        background: white;
        box-shadow: 0 10px 22px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
    }
    .message-container-block .message-contact{
        padding: 30px;
        display: flex;
        flex-direction: column;
        width: 24%;
        height: 100%;
        border-right: 1px solid #efefef;
    }
    .message-container-block .chat-container{
        display: flex;
        flex-direction: column;
        width: 76%;
        height: 100%;
    }
    .message-contact .search-contact{
        width: 100%;
        height: 30px;
    }
    .search-contact input{
        padding: 5px;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        border: 1px solid #efefef;
        outline: none;
        background: transparent;
    }
    .message-contact .contact-contain{
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
    }
    .contact-contain .contact-item{
        margin-bottom: 25px;
        display: flex;
        align-items: center;
        width: 100%;
        height: auto;
        cursor: pointer;
    }
    .contact-item .avatar-contact{
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background: teal;
    }
    .avatar-contact img{
        height: 50px;
        width: 50px;
        border-radius: 50%;
        object-fit: cover;
    }
    .contact-item .contact-info{
        padding-left: 10px;
        display: flex;
        align-items: center;
        width: calc(100% - 50px);
        height: auto;
    }
    .contact-info .info-user{
        display: flex;
        flex-direction: column;
        height: 100%;
        width: auto;
    }
    .info-user .contact-name{
        font-weight: 600;
    }
    .info-user .contact-message{
        font-size: .85rem;
        line-height: 1rem;
    }
    .contact-info .unread-message{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 30px;
        background: #F5365C;
        color: white;
        font-weight: bold;
        text-align: center;
    }
    .chat-container-header{
        padding: 10px 5%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 75px;
        width: 100%;
        border-bottom: 1px solid #efefef;
    }
    .chat-container-header .active-contact{
        display: flex;
        align-items: center;
    }
    .active-contact img{
        margin-right: 10px;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        object-fit: cover;
    }
    .active-contact span{
        font-size: 1rem;
        font-weight: 600;
    }
    .chat-container-header .controls{
        display: flex;
        align-items: center;
        height: 100%;
    }
    .controls div{
        padding: 8px;
        font-size: 1rem;
        cursor: pointer;
        border-radius: 5px;
        transition: .1s ease;
        color: #707070;
    }
    .controls div:hover{
        background: #f0f0f0;
        color: #191c22;
    }
    .controls div i{
        margin-right: 5px;
    }
    .controls div:nth-child(1){
        margin-right: 10px;
    }
    .chat-container .chat-area{
        padding: 5%;
        height: calc(100% - 150px);
        width: 100%;
        overflow-y: auto;
    }
    .chat-area .message-item{
        display: flex;
        align-items: center;
        height: auto;
        width: 100%;
    }
    .chat-area .message-item.right{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: auto;
        width: 100%;
    }
    .message-item img{
        margin-right: 5px;
        height: 30px;
        width: 30px;
        object-fit: cover;
        border-radius: 50%;
    }
    .message-item p{
        padding: 20px;
        height: auto;
        width: auto;
        max-width: 700px;
        border-radius: 8px;
        background: #f0f0f0;
    }
    .chat-area .message-item.right p{
        background: #efefef;
    }
    .chat-container .chat-container-bottom{
        padding: 10px 5%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 75px;
        width: 100%;
        border-top: 1px solid #efefef;        
    }
    .chat-container-bottom input{
        padding-left: 10px;
        width: calc(100% - 110px);
        height: 90%;
        background: #efefef;
        border-radius: 4px;
        border: none;
        transition: .1s ease;
    }
    .chat-container-bottom input:focus{
        background: #ddd;
    }
    .chat-container-bottom button{
        margin-left: 10px;
        width: 100px;
        height: 90%;
        background: #F5365C;
        border-radius: 4px;
        border: none;
        color: white;
    }
    .chat-container-bottom button i{
        margin-right: 5px;
    }
    @media only screen and (max-width: 800px){
        .message-container-block .message-contact,.chat-container{
            display: none;
        }
        .message-container-block .message-contact.visible{
            padding: 30px;
            display: flex;
            width: 100%;
            animation: rightToLeft .5s;
        }
        .message-container-block .chat-container.visible{
            display: flex;
            width: 100%;
            animation: leftToRight .5s;
        }
        @keyframes leftToRight {
            0%{
            transform: translateX(200px);
            }
            100%{
            transform: translateX(0px);
            }
        }
        @keyframes rightToLeft {
            0%{
            transform: translateX(-200px);
            }
            100%{
            transform: translateX(0px);
            }
        }
        
        .contact-contain .contact-item{
            padding-bottom: 10px;
            border-bottom: 1px solid #f0f0f0;
        }
        .message-item p{
            padding: 10px 20px;
            height: auto;
            width: auto;
            max-width: 85%;
            text-align: left;
        }
        .chat-area .message-item.right p{
            background: #7a7a7a;
            text-align: right;
            color: white;
        }
        .chat-container-header{
            padding: 10px 5%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 55px;
        }
        .chat-container .chat-area{
            height: calc(100% - 130px);
        }
        .active-contact img{
            margin-right: 5px;
            height: 30px;
            width: 30px;
        }
        .controls div{
            padding: 0px;
            font-size: 1rem;
            transition: none;
            color: #191c22;
        }
        .controls div:nth-child(1){
            margin-right: 5px;
        }
        .controls div span{
            display: none;
        }
        .controls div:hover{
            background: transparent;
            color: #191c22;
        }
    }
</style>